import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Base from '../views/Base.vue'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/base',
    name: 'Base',
    component: Base,
    children: [
      {
        path: '/home',
        name: 'Home',
        component: Home,
        meta: { title: '控制台' }
      },
      {
        path: '/goods',
        name: 'Goods',
        component: () => import('../views/Goods.vue'),
        meta: { title: '新增商品' }
      },
      {
        path: '/goods_edit/:id',
        name: 'GoodsEdit',
        component: () => import('../views/Goods_edit.vue'),
        meta: { title: '编辑商品' }
      },
      {
        path: '/goods_list',
        name: 'Goods_list',
        component: () => import('../views/Goods_list.vue'),
        meta: { title: '商品列表' }
      },
      {
        path: '/category_list',
        name: 'Category_list',
        component: () => import('../views/Category_list.vue'),
        meta: { title: '分类列表' }
      },
      {
        path: '/wx_category',
        name: 'Wx_category',
        component: () => import('../views/Wx_category.vue'),
        meta: { title: '微信小程序分类页设置' }
      },
      {
        path: '/wx_home',
        name: 'Wx_home',
        component: () => import('../views/Wx_home.vue'),
        meta: { title: '微信小程序首页设置' }
      },
      {
        path: '/wx_base',
        name: 'Wx_base',
        component: () => import('../views/Wx_base.vue'),
        meta: { title: '微信小程序基础信息设置' }
      },
      {
        path: '/wx_misc_set',
        name: 'Wx_misc_set',
        component: () => import('../views/Wx_misc_set.vue'),
        meta: { title: '微信小程序多项设置' }
      },
      {
        path: '/order_list',
        name: 'Order_list',
        component: () => import('../views/Order_list.vue'),
        meta: { title: '订单列表' }
      },
      {
        path: '/user_list',
        name: 'User_list',
        component: () => import('../views/User_list.vue'),
        meta: { title: '用户列表' }
      }
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: { title: '登录' }
  }

]

const router = new VueRouter({
  routes
})
router.beforeEach((to, from, next) => {
  var token = sessionStorage.getItem('token')
  if (to.meta.title) {
    document.title = to.meta.title
  }
  if (to.name !== 'Login' && token === null) next({ name: 'Login' })
  else next()
})

export default router
