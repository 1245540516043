<template>
  <div class="base">
    <div class="main_left">
      <div class="logo_wrap" :style="'width:'+ nav_width +'px'">
        <div class="logo-area"><img :class="{'logobig':!isCollapse , 'logo':isCollapse}" v-bind:src="logoimage"></div>
      </div>
      <div>
        <el-menu :uniqueOpened="true" default-active="1" class="el-menu-vertical-demo" :collapse="isCollapse"
          background-color="#191a23"
          text-color="#fff"
          active-text-color="#ffd04b"
          :router="true"
          >
          <el-menu-item index="1" route="/home">
            <i class="el-icon-menu"></i>
            <span>控制台</span>
          </el-menu-item>
          <el-submenu index="2">
            <template #title>
              <i class="el-icon-box"></i>
              <span>商品管理</span>
            </template>
            <el-menu-item index="2-1" route="/goods">新增商品</el-menu-item>
            <el-menu-item index="2-2" route="/goods_list">商品列表</el-menu-item>
          </el-submenu>
          <el-submenu index="3">
            <template #title>
              <i class="el-icon-files"></i>
              <span>分类管理</span>
            </template>
            <el-menu-item index="3-1" route="/category_list">分类列表</el-menu-item>
          </el-submenu>
          <el-submenu index="4">
            <template #title>
              <i class="el-icon-mobile"></i>
              <span>微信小程序设置</span>
            </template>
            <!-- <el-menu-item index="4-1" route="/wx_base">基础信息设置</el-menu-item> -->
            <el-menu-item index="4-2" route="/wx_home">首页设置</el-menu-item>
            <el-menu-item index="4-3" route="/wx_category">分类页设置</el-menu-item>
            <el-menu-item index="4-4" route="/wx_misc_set">多项设置</el-menu-item>
          </el-submenu>
          <el-submenu index="5">
            <template #title>
              <i class="el-icon-s-order"></i>
              <span>订单管理</span>
            </template>
            <el-menu-item index="5-1" route="/order_list">全部订单</el-menu-item>
          </el-submenu>
          <el-submenu index="6">
            <template #title>
              <i class="el-icon-s-custom"></i>
              <span>用户管理</span>
            </template>
            <el-menu-item index="6-1" route="/user_list">用户列表</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
    </div>
    <div class="main_right" :style="'padding-left:'+ nav_width +'px'">
      <div class="top_header">
        <div class="top_header_left">
          <i class="el-icon-s-unfold switch_icon" @click="handleSwitch"></i>
          <div class="page_title">{{getBreadcrumb}}</div>
        </div>
        <div class="top_header_right">
          <!-- <div class="top_header_right_notice">
            <el-badge :value="1" :max="99" class="item">
              <router-link to="/order?type=1"> <i class="el-icon-monitor"></i></router-link>
            </el-badge>
          </div> -->
          <el-dropdown>
            <span class="el-dropdown-link">
              <div class="top_header_avatar_wrap">
                <span class="top_header_username">{{staff.name}}</span>
                <el-avatar shape="square" size="small" alt="用户头像"  icon="el-icon-user-solid"></el-avatar>
              </div>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="el-icon-user" disabled>个人中心</el-dropdown-item>
              <el-dropdown-item icon="el-icon-set-up" disabled>设置</el-dropdown-item>
              <el-dropdown-item icon="el-icon-switch-button" divided @click.native="handleExit">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="main_content">
        <router-view></router-view>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Base',
  data () {
    return {
      staff: {},
      isCollapse: true,
      nav_width: 64,
      logoimage: '/image/logo_4.png',
      logoimagepath: ['/image/logo_4.png', '/image/logo_3.png']
    }
  },
  methods: {
    handleSwitch () {
      this.isCollapse = !this.isCollapse
      if (this.isCollapse) {
        console.log('关')
        this.nav_width = 64
        this.logoimage = this.logoimagepath[0]
      } else {
        console.log('开')
        this.nav_width = 256
        this.logoimage = this.logoimagepath[1]
      }
    },
    handleExit () {
      sessionStorage.removeItem('token')
      this.$router.push('Login')
    },
    getStaffInfo () {
      var staff = sessionStorage.getItem('staff')
      this.staff = JSON.parse(staff)
    }
  },
  computed: {
    getBreadcrumb: function () {
      return this.$route.meta.title
    }
  },
  mounted () {
    this.getStaffInfo()
  }
}
</script>

<style>
  #app, body, html{
    height: 100%;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }
  .main_wrap{
    display: flex;
    height: 100%;
    flex: auto;
    width: 100%;
  }
  .main_left{
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    background-color: #191a23;
    box-shadow: 2px 0 6px rgb(0 21 41 / 35%);
    z-index: 99;
    overflow: auto;
  }
  .main_left::-webkit-scrollbar{
    width:0;
  }
  .logo_wrap{
    height: 64px;
    display: flex;
    justify-content: center;
    background-color: #191a23;
    border-bottom: 1px solid #101117;
    position: sticky;
    top: 0;
    z-index: 100;
    transition: width 0.3s;
    overflow: hidden;
  }
  .logobig{
    width: 60%;
  }
  .logo{
    width: 100%;
  }
  .logo-area{
    margin: auto;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
    text-align: center;
  }
  .base{
    width: 100%;
  }
  .main_right{
    width: 100%;
    box-sizing: border-box;
    transition: all 0.3s;
  }
  .el-menu {
    border-right: none !important
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 256px;
    min-height: 400px;
  }
  .top_header{
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 64px;
    box-shadow: 0 1px 4px rgb(0 21 41 / 8%);
    position: relative;
    position: sticky;
    z-index: 98;
    top: 0;
  }
  .top_header_left,.top_header_right{
    display: flex;
    align-items: center;
  }
  .top_header_right{
    padding-right: 20px;
  }
  .top_header_right_notice{
    cursor: pointer;
  }
  .top_header_avatar_wrap{
    display: flex;
    align-items: center;
  }
  .top_header_username{
    padding: 0 20px;
    color: #333333;
  }
  .switch_icon{
    padding:0 20px;
  }
  .main_content{
    box-sizing: border-box;
    padding: 20px;
    width: 100%;
  }
  .page_title{
    position: relative;
    padding-left: 20px;
  }
  .page_title::before{
    content: "";
    position: absolute;
    width: 1px;
    height: 16px;
    left: 0px;
    top: 58%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    background-color: #DCDFE6
  }
  .el-dialog .default_row {
    color: red;
  }
  .el-pagination{
    margin-top: 30px;
    text-align: center;
  }
  .order_badge{
    background-color: #f56c6c;
    color: #ffffff;
    padding: 0px 2px;
    border-radius: 3px;
    font-size: 12px;
    margin-left: 5px;
  }
</style>
