<template>
  <div class="login">
    <div class="login_area">
      <div class="login_left">
        <div class="title">登录</div>
          <div class="form-wrap">
            <el-form label-position="left" :model="formLogin" @submit.native.prevent>
              <el-form-item>
                <el-input v-model="formLogin.account" placeholder="账号"></el-input>
              </el-form-item>
              <el-form-item>
                <el-input v-model="formLogin.password" placeholder="密码" show-password></el-input>
              </el-form-item>
              <el-form-item>
                <button class="login_btn" @click="handleSubmit">登录</button>
              </el-form-item>
            </el-form>
          </div>
      </div>
      <div class="login_right">
        <div class="login_logo_wrap">
          <img v-if="logo.show" :src="logo.content" class="logo_image">
          <h1 class="brand" v-if="brand.show">{{brand.content}}</h1>
          <p v-if="slogon.show">{{slogon.content}}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Login',
  data () {
    return {

      logo: {
        content: '/image/logo_3.png',
        show: 0
      },
      slogon: {
        content: 'Just the Kind of Life.',
        show: 1
      },
      brand: {
        content: 'JKL CASE',
        show: 1
      },
      formLogin: {
        account: '',
        password: ''
      }
    }
  },
  methods: {
    handleSubmit () {
      var that = this
      this.axios.post('admin/login', that.formLogin).then(function (response) {
        if (response.data.code === 0) {
          sessionStorage.setItem('token', response.data.token)
          var staff = JSON.stringify(response.data.staff)
          sessionStorage.setItem('staff', staff)
          that.$router.push('Home')
        }
        if (response.data.code === 1) {
          that.$message({
            message: response.data.msg,
            type: 'error'
          })
        }
      }).catch(function (error) {
        console.log(error)
      })
    }
  }
}
</script>

<style scoped>
.login{
  height: 100%;
  width: 100%;
  background: #f6f5f7;
  display: flex;
  justify-content: center;
}
.login_area{
  display: flex;
}
.login_left,.login_right{
  width: 50%;
  height: 100%;
}
.login_right{
  color: red;
  background-color: #ff416c;
  background: linear-gradient(to right, #ff4b2b, #ff416c) no-repeat 0 0 / cover;
}
.login_area{
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 14px 28px rgb(0 0 0 / 25%), 0 10px 10px rgb(0 0 0 / 22%);
  position: relative;
  overflow: hidden;
  width: 768px;
  height: 380px;
  margin: auto;
}
.login_left{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.form-wrap{
  padding: 30px;
  box-sizing: border-box;
  margin: 30px 20px;
}
.title{
  text-align: center;
  font-weight: bold;
  font-size: 28px;
}
.login_btn{
  width: 100%;
  border-radius: 30px;
  border: 1px solid #ff4b2b;
  background: #ff4b2b;
  color: #fff;
  font-size: 17px;
  padding: 12px 45px;
  letter-spacing: 1px;
  text-transform: uppercase;
  transition: transform 80ms ease-in;
  cursor: pointer;
  outline: none;
}
.login_right{
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.login_logo_wrap{
  text-align: center;
  width: 100%;
  color: white;
}
.logo_image{
  width: 60%;
}
.brand{
  font-size: 45px;
}
</style>
