<template>
  <div class="home">
    <el-row :gutter="20" class="data-row">
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">访问次数</div>
              <div class="data-item-num">{{dashboard.visitor}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/visitor.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">用户数</div>
              <div class="data-item-num">{{dashboard.users}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/user.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">宝贝数</div>
              <div class="data-item-num">{{dashboard.goods}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/goods.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">收藏数</div>
              <div class="data-item-num">{{dashboard.wishlist}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/collection.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20" class="data-row">
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">订单金额</div>
              <div class="data-item-num">{{dashboard.amount}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/money.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">订单数</div>
              <div class="data-item-num">{{dashboard.orders}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/order.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">待发货</div>
              <div class="data-item-num">{{dashboard.tosend}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/tosend.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
      <el-col :span="6">
        <el-card class="box-card data-item">
          <div class="data-item-wrap">
            <div class="data-item-left">
              <div class="data-item-title">待付款</div>
              <div class="data-item-num">{{dashboard.topay}}</div>
            </div>
            <div class="data-item-right">
              <div class="data-item-icon">
                <img src="/image/home/aftersale.png">
              </div>
            </div>
          </div>
        </el-card>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <el-col :span="18">
        <div id="visitirline"></div>
        <div id="orderbar"></div>
      </el-col>
      <el-col :span="6">
        <div id="stylepie"></div>
        <div id="categorypie"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import * as echarts from 'echarts'
export default {
  name: 'Home',
  data () {
    return {
      dashboard: ''
    }
  },
  methods: {
    initVisitirline () {
      var that = this
      var x = []
      var y = []
      that.dashboard.visitor_line.forEach(element => {
        x.push(element.time)
        y.push(element.amount)
      })
      // 基于准备好的dom，初始化echarts实例
      var visitirline = echarts.init(document.getElementById('visitirline'))
      // 绘制图表
      visitirline.setOption({
        title: {
          text: '访问次数月数据'
        },
        tooltip: {},
        grid: {
          left: '3%',
          right: '3%',
          bottom: '8%'
        },
        xAxis: {
          data: x
        },
        yAxis: {},
        series: [{
          smooth: true,
          name: '访问量',
          type: 'line',
          data: y
        }]
      })
    },
    initOrderbar () {
      var that = this
      var x = []
      var y = []
      that.dashboard.order_line.forEach(element => {
        x.push(element.time)
        y.push(element.amount)
      })
      // 基于准备好的dom，初始化echarts实例
      var orderbar = echarts.init(document.getElementById('orderbar'))
      // 绘制图表
      orderbar.setOption({
        title: {
          text: '订单量月数据'
        },
        tooltip: {},
        grid: {
          left: '3%',
          right: '3%',
          bottom: '8%'
        },
        xAxis: {
          data: x
        },
        yAxis: {},
        series: [{
          smooth: true,
          name: '金额',
          type: 'bar',
          data: y
        }]
      })
    },
    initStylePie () {
      var that = this
      var stylepie = echarts.init(document.getElementById('stylepie'))
      stylepie.setOption({
        title: {
          text: '风格分布',
          left: 'left'
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            saveAsImage: { show: true }
          }
        },
        series: [
          {
            type: 'pie',
            radius: [10, '75%'],
            center: ['50%', '50%'],
            roseType: 'area',
            itemStyle: {
              borderRadius: 8
            },
            data: that.dashboard.style
          }
        ]
      })
    },
    initCategoryPie () {
      var that = this
      var categorypie = echarts.init(document.getElementById('categorypie'))
      categorypie.setOption({
        title: {
          text: '种类分布',
          left: 'left'
        },
        tooltip: {
          trigger: 'item'
        },
        series: [
          {
            name: '种类占比',
            type: 'pie',
            radius: ['20%', '70%'],
            avoidLabelOverlap: false,
            itemStyle: {
              borderRadius: 10,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {

            },
            emphasis: {
              label: {
                show: true,
                fontSize: '20',
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: true
            },
            data: that.dashboard.category
          }
        ]
      })
    },
    getData () {
      var that = this
      this.axios.get('admin/index/data')
        .then(function (response) {
          that.dashboard = response.data
          that.initVisitirline()
          that.initOrderbar()
          that.initStylePie()
          that.initCategoryPie()
        })
    }
  },
  mounted () {
    this.getData()
  }
}
</script>

<style scoped>
.data-row{
  margin-bottom: 20px;
}
.data-item-wrap{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.data-item-num{
  font-size: 35px;
}
.data-item-icon > img{
  width: 50px;
  height: 50px;
}
#visitirline,#orderbar,#stylepie,#categorypie{
  height: 280px;
  width: 100%;
}

</style>
